@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.App {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
}

.app-logo {
  opacity: 0.9;
}

.app-logo:hover {
  opacity: 1.0;
}

.heading {
  font-size: large;
}

.app-load-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  margin-top: -60px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #e5bdff;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.form-subsection {
  border: 0.2rem dotted rgba(170, 50, 220, .2);
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown-modal {
  overflow: visible;
}

.dropdown-modal .modal-card {
  overflow: visible;
}

.dropdown-modal .modal-card-body {
  overflow: visible;
}

.dropdown-content {
  max-height: 30vh;
  overflow: auto;
}

.color-circle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  opacity: 0.8;
  border-color: rgb(223, 211, 211);
  border-style: solid double;
  border-width: 2px;
  margin-left: 0.5px;
  margin-right: 0.5px;
  display: inline-block;
}

.color-circle.is-active {
  opacity: 1;
  height: 40px;
  width: 40px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-2-lines {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 3em;
}

.columns.is-vcentered {
  align-items: center; /* if an only child */
}

.box:hover {
  opacity: 0.9;
}

.has-bg-img { overflow: auto; position: relative }
.has-bg-img::before {
   content:"";
   position: absolute;
   z-index: -1;
   display: block;
   width: 100%;
   height: 100%;
   /* background: url('clouds.jpg') center center; */
   background-size: cover;
   filter: opacity(0.6);
}

.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
/* .hero-background.is-transparent {
  opacity: 0.3;
} */

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

* {outline: none;}

.btn:focus {
    outline: none;
}

.fa-edit {
  cursor: pointer;
}

.fa-plus:hover {
  transform: scale(1.2);
}

.navbar-item {
  will-change: transform;
}

.button {
  will-change: transform;
}

.is-hoverable.fa {
  opacity: 0.6;
}

.is-hoverable.fa:hover {
  opacity: 1;
}

.is-editable .fa-binoculars {
    position: absolute;
    z-index: 1;
    will-change: transform;
    top: 1em;
    left: 1em;
}

.is-editable .fa-edit {
    position: absolute;
    z-index: 1;
    will-change: transform;
    top: 1em;
    right: 1em;
}

@media only screen and (min-width: 768px) {
  .is-editable:not(.show-edit-icons) .fa-edit {
    visibility: hidden;
  }
}

.is-editable:not(.show-edit-icons) .fa-binoculars {
  cursor: pointer;
  position: absolute;
  visibility: hidden;
}

.fa-star {
    cursor: pointer;
}

.is-editable .fa-star {
    position: absolute;
    z-index: 1;
    will-change: transform;
    bottom: 1em;
}

@media only screen and (min-width: 768px) {
    .is-editable:not(.show-edit-icons) .fa-star {
        visibility: hidden;
    }
}

.is-editable:not(.show-edit-icons) > .fa {
    position: absolute;
    visibility: hidden;
}

.is-editable > .fa-camera {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.is-editable > .fa-trash {
    top: 5%;
    right: 5%;
}

.fa:not(.fa-camera):not(.disable-hover-transform):hover {
  transform: scale(1.5);
  transition: 0.1s;
}

.is-editable {
  position: relative;
}

* > .editable-child {
    position: relative;
    visibility: hidden;
}

@media only screen and (max-width: 768px) {
    .editable-child,
    .fa {
      visibility: visible;
    }
  }

.is-editable:hover .editable-child,
.is-editable:hover .fa-camera,
.is-editable:hover .fa-trash,
.is-editable:hover .fa-edit,
.is-editable:hover .fa-binoculars,
.is-editable:hover .fa-star {
    visibility: visible;
}

:root {
  --inactive-image: opacity(10%);
}

@media (hover: hover) and (pointer: fine) {
  .is-clickable:hover img {
  transition: 0.3s ease;
    filter: var(--inactive-image);
  }
}

.is-editable:hover img {
  transition: 0.3s ease;
  filter: var(--inactive-image);
}

.inactive-image {
  filter: var(--inactive-image);
}

tr {
  border-bottom: 1px solid #ddd;
}

:root {
  --category-card-image: 10em
}

@media only screen and (max-width: 992px) {
  :root {
    --category-card-image: 10em
  }
}

.collapsible {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.collapsible.collapsible-active {
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    transform: translateX(-50%);
    bottom: 30px; /* 30px from the bottom */
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
    However, delay the fade out process for 4.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
    animation: fadein 0.5s, fadeout 0.5s 4.5s;
  }
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  