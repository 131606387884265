.gallery-scroll-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.gallery-scroll-container-snappy {
  scroll-snap-type: x mandatory;
}

.gallery-scroll-container::-webkit-scrollbar {
  display: none;
}
.gallery-scroll-item {
  scroll-snap-align: center;
}
